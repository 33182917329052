@import "color-palette.scss";

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: map-get($primary-palette, 50);
}

.bg-black {
  background: black;
}

.center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

progress::-webkit-progress-value {
  transition: width 0.1s ease;
}

em {
  background-color: map-get($secondary-palette, 500);
  font-style: normal;
}

@media (max-width: 500px) {
  .listing-card {
    min-width: 250px;
    width: 100%;
  }
}

@media (min-width: 501px) {
  .listing-card {
    min-width: 250px;
    width: 302px;
    max-width: 400px;
  }
}

.no-paging .mat-tab-labels {
  flex-wrap: wrap;
}

.alert-box-actions {
  position: absolute;
  top: -5px;
  right: -5px;
}

/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.display-1 {
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-weight: 300;
  line-height: 1.2;
}

.jumbotron-headline {
  font-weight: 300;
  line-height: 1.2;
}

.paragraph-headline {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.paragraph-headline-2 {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.2;
}

.paragraph-headline-3 {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.2;
}

.paragraph-headline-4 {
  font-size: 1.0rem;
  font-weight: 400;
  line-height: 1.0;
}

.text-red {
  color: #F44336;
}

/* Cropping images */
.cropped-400h {
  object-fit: cover;
  height: 400px;
}

.cropped-300h {
  object-fit: cover;
  height: 300px;
}

.cropped-300h-300w {
  object-fit: cover;
  width: 300px;
  height: 300px;
}

.cropped-500h-500w {
  object-fit: cover;
  width: 500px;
  height: 500px;
}

.cropped-150h {
  object-fit: cover;
  height: 150px;
}

.cropped-200h-140w {
  object-fit: cover;
  height: 200px;
  width: 140px;
}

.cropped-200h-150w {
  object-fit: cover;
  height: 200px;
  width: 150px;
}

.cropped-150h-150w {
  object-fit: cover;
  height: 150px;
  width: 150px;
}

.cropped-100h-100w {
  object-fit: cover;
  height: 100px;
  width: 100px;
}

.cropped-120w-80h {
  object-fit: cover;
  width: 120px;
  height: 80px;
}

.cropped-60w-60h {
  object-fit: cover;
  width: 60px;
  height: 60px;
}

/** Sizing */
.min-w-350px {
  min-width: 300px;
}

.min-w-250px {
  min-width: 250px;
}

.min-w-200px {
  min-width: 200px;
}

.min-w-150px {
  min-width: 150px;
}

.min-w-100px {
  min-width: 100px;
}

.min-w-75px {
  min-width: 75px;
}

.min-w-50px {
  min-width: 50px;
}

.max-w-500px {
  max-width: 500px;
}

.max-w-768px {
  max-width: 768px;
}

.w-130px {
  width: 130px;
}


.w-300px {
  width: 300px;
}

.w-150px {
  width: 150px;
}


.col-05 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 4.166665%;
  flex: 0 0 4.166665%;
  max-width: 4.166665%;
}

.col-25 {
  -ms-flex: 0 0 20.833333%;
  flex: 0 0 20.833333%;
  max-width: 20.833333%;
}

.col-075 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 6.249975%;
  flex: 0 0 6.249975%;
  max-width: 6.249975%;
}

.col-sm-35 {
  -ms-flex: 0 0 29.16665%;
  flex: 0 0 29.16665%;
  max-width: 29.16665%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* Margin */

.m-05 {
  margin: 0.125rem !important;
}

.mt-05,
.my-05 {
  margin-top: 0.125rem !important;
}

.mr-05,
.mx-05 {
  margin-right: 0.125rem !important;
}

.mb-05,
.my-05 {
  margin-bottom: 0.125rem !important;
}

.ml-05,
.mx-05 {
  margin-left: 0.125rem !important;
}

/* Padding */

.p-05 {
  padding: 0.125rem !important;
}

.pt-05,
.py-05 {
  padding-top: 0.125rem !important;
}

.pr-05,
.px-05 {
  padding-right: 0.125rem !important;
}

.pb-05,
.py-05 {
  padding-bottom: 0.125rem !important;
}

.pl-05,
.px-05 {
  padding-left: 0.125rem !important;
}

.bg-gray {
  background-color: #d7d7d7 !important;
}


.virtual-scroll-x-container {
  width: calc(100vw);
  overflow-x: auto;
}

.myCustomModalClass .modal-dialog {
  max-width: 100%;
  margin: 1%;
  display: inline-block;
}

/* Froms */
.character-counter {
  float: right;
  font-size: 12px;
  height: 1px;
  margin-top: 0.5rem !important;
}

/* Form validation */


/* Material form */

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #FFFFFF;
}

.mat-form-textarea-w-100 {
  min-width: 200px;
  width: 100%
}

.mat-select-panel-search {
  max-height: 90% !important;
}

.mat-select-panel-500px {
  max-height: 40em !important;
}

/* mat-form-field standard */

/*.mat-input-element {*/
/*  color: green;*/
/*}*/

/*.mat-form-field-label {*/
/*  color: green;*/
/*}*/

/*.mat-form-field-underline {*/
/*  background-color: green;*/
/*}*/

/*.mat-form-field-ripple {*/
/*  background-color: green;*/
/*}*/

/*.mat-form-field-required-marker {*/
/*  color: green;*/
/*}*/

/* see https://stackoverflow.com/questions/48540533/styling-mat-form-field-input-in-angular-material */

/* Button styles */
.btn-accent {
  background-color: map-get($secondary-palette, 500);;
  color: map-get($primary-palette, 500);;
}

.btn-accent:hover {
  background-color: map-get($primary-palette, 500) !important;
  border-radius: 3px;
  color: map-get(map-get($primary-palette, contrast), 500);
  box-shadow: 0 0 0 1px map-get($secondary-palette, 500);
  transition: all 200ms ease-out;
}

.btn-accent:active {
  background-color: map-get($primary-palette, 500) !important;
  color: map-get($secondary-palette, 500);
  border-radius: 3px;
  box-shadow: 0 0 0 1px map-get($secondary-palette, 500);
  transition: all 200ms ease-out;
}

.broad-button {
  width: 100%;
}


.mat-mini-fab .mat-button-wrapper {
  padding: 0 !important;
}

.mat-icon-button {
  line-height: unset;
}

.breaking-text {
  white-space: break-spaces;
}

/* Makes a link stretch all the way over an element (like a card) */
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}


.text-input-100 {
  width: 100%;
}

.text-input {
  min-width: 250px;
  width: calc(100vw - 64px);
  max-width: 100%;
}

.text-input-long {
  width: 100%;
  max-width: 250px;
}

.text-input-longer {
  width: 350px;
}

.text-input-extra-long {
  width: 450px;

}

.text-input-mini {
  width: 150px;
}

.material-time-picker {
  display: flex;
}

.conversations-dialog-container .mat-dialog-container {
  position: absolute;
  top: 32px;
  right: 32px;
  display: block;
  padding: 0px 12px 0px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow-x: hidden !important;
  overflow-y: auto;
  outline: 0;
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);
  max-width: 300px;
  max-height: 600px;
}

.no-underline:hover {
  text-decoration: none;
}

.hover-pointer:hover {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-with-text {
  display: flex;
  align-items: center;
}


.invisible {
  display: none;
}

.info-success {
  color: #009000;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #00FF00;
  border-radius: 0.25rem;
}

.info-danger {
  color: #900000;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #FF0000;
  border-radius: 0.25rem;
}


.left {
  text-align: left;
}

.right {
  text-align: right;
}

.card-icon {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.text-shadow-primary {
  text-shadow: 0 0 2vw map-get($primary-palette, 500), 0 0 2vw map-get($primary-palette, 500), 0 0 2vw map-get($primary-palette, 500);
}

/* Desktop size */
@media (min-width: 768px) {
  .thumbnail {
    object-fit: cover;
    height: 100px;
    width: 100px;
  }
  /* A large material icon to be used inside cards */
  .card-icon {
    font-size: 150px; /* Preferred icon size */
  }

  .display-1 {
    font-size: 6rem;
  }

  .display-2 {
    font-size: 5.5rem;
  }

  .display-3 {
    font-size: 4.5rem;
  }

  .display-4 {
    font-size: 3.5rem;
  }

  .display-5 {
    font-size: 2.5rem;
  }

  .display-6 {
    font-size: 1.5rem;
  }

  .jumbotron-headline {
    font-size: 3.5rem;
  }
  .jumbotron-lead {
    font-size: 1.25rem;
    font-weight: 300;
  }

  .listing-image-table {
    object-fit: cover;
    width: 150px;
    height: 150px;
  }

}

/* mobile size */
@media (max-width: 767px) {
  .thumbnail {
    object-fit: cover;
    height: 60px;
    width: 60px;
  }

  /* A large material icon to be used inside cards */
  .card-icon {
    font-size: 50px; /* Preferred icon size */
  }


  .display-1 {
    font-size: 3rem;
  }

  .display-2 {
    font-size: 2.5rem;
  }

  .display-3 {
    font-size: 2.0rem;
  }

  .display-4 {
    font-size: 1.5rem;
  }

  .display-5 {
    font-size: 1.0rem;
  }

  .display-6 {
    font-size: 1.0rem;
  }

  .jumbotron-headline {
    font-size: 1.5rem;
  }
  .jumbotron-lead {
    font-size: 1rem;
    font-weight: 300;
  }

  .listing-image-table {
    object-fit: cover;
    width: 75px;
    height: 75px;
  }

}

// Adding xs breakpoint to bootstrap breakpoints
// Bootstrap comes with: Extra small <576px,	Small ≥576px,	Medium ≥768px,	Large ≥992px,	Extra large ≥1200px
@media (min-width: 432px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.mat-expansion-panel-without-padding .mat-expansion-panel-body {
  padding: 0 !important;
}

// Animated ngb-carousel. For animations to be active, add the class 'animatedCarousel' to <ngb-carousel>
.animatedCarousel .carousel-item {
  transition: opacity 1.5s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.animatedCarousel .carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.top-rounded-image {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

button {
  white-space: pre-wrap !important;
}

.highlight-text {
  text-shadow: 0 0 10px map-get($primary-palette, 500);
}
