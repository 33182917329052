@use '~@angular/material' as mat;
@import "bootstrap-custom";
@import '~@angular/material/theming';
@import 'color-palette';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$aSchwartz-primary: mat.define-palette($primary-palette);
//$candy-app-primary: $secondary;
$aSchwartz-accent: mat.define-palette($secondary-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$aSchwartz-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$aSchwartz-app-theme: mat.define-light-theme((
  color: (
    primary: $aSchwartz-primary,
    accent: $aSchwartz-accent,
    secondary: $aSchwartz-accent,
    warn: $aSchwartz-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($aSchwartz-app-theme);


// Workaround for https://github.com/angular/material2/issues/4580.
mat-form-field .mat-form-field {
  &-underline {
    position: relative;
    bottom: auto;
  }

  &-subscript-wrapper {
    position: static;
  }
}
